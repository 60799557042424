<template>
    <label class="label">
        <div
            v-if="$slots['top_big']"
            class="label__top_big"
        >
            <slot name="top_big" />
        </div>
        <div
            v-if="$slots['input']"
            class="label__top_input-wrapper"
        >
            <div class="label__top_input">
                <span
                    class="label__bg-white"
                    :class="{
                        _invalid: !!validationMessage,
                    }"
                >
                    <slot name="input" />
                </span>
            </div>
        </div>
        <div class="label-element">
            <slot />
        </div>
        <div
            v-if="validationMessage"
            class="label-validation-message"
            :class="{
                _grow: isValidationGrow,
            }"
        >
            {{ validationMessage }}
        </div>
    </label>
</template>

<script>
export default {
    name: "Label", // eslint-disable-line vue/no-reserved-component-names
    props: {
        validationMessage: {
            default: "",
            type: String,
        },
        isValidationGrow: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss" src="./label.scss"></style>
