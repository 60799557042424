import borrowApi from "@frontend/allspark/infra/jsonrpc/borrow/index.js";
import tracker from "@frontend/allspark/infra/tracker/index.js";
import offerswitcher from "@frontend/allspark/infra/offerswitcher/index.js";

export default {
    tracker:{
        ...tracker,
    },
    jsonrpc: {
        ...borrowApi,
    },
    offerswitcher: {
        ...offerswitcher
    }
};
