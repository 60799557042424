<template>
    <input
        ref="input"
        :value="val"
        class="input"
        :class="[
            '_type_' + styleType,
            '_input-type_' + type,
            '_input-clear_' + (modelValue ? 'no' : 'yes'),
        ]"
        :name="name"
        :type="type === 'sdate' ? 'text' : type"
        :max="trueMax"
        :min="min"
        :step="step"
        :pattern="pattern"
        :inputmode="inputmode"
        :autocapitalize="autocapitalize"
        @input="(evt) => (val = evt.target.value)"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @click="$emit('click')"
        @change="onChange(val)"
    >
</template>



<script>
import {
    servicePhoneMask,
    checPositionAfterMask,
    setNumberMask,
    setCapitalizeMask,
    serviceDateMask,
} from "./services/mask/mask";

export default {
    name: "Input", // eslint-disable-line vue/no-reserved-component-names
    props: [
        "name",
        "modelValue",
        "type",
        "max",
        "min",
        "step",
        "validationMessage",
        "maxlength",
        "pattern",
        "inputmode",
        "mask",
        "autocapitalize",
    ],
    computed: {
        trueMax() {
            if (Number(this.max) > 0) {
                return (
                    Math.ceil(Number(this.max) / Number(this.step)) * Number(this.step)
                );
            }

            return this.max;
        },
        styleType() {
            return (
                {
                    number: "text",
                    tel: "text",
                    email: "text",
                    date: "text",
                    sdate: "text",
                }[this.type] || this.type
            );
        },
        val: {
            get() {
                if (this.modelValue && this.maxlength) {
                    return this.modelValue.slice(0, this.maxlength);
                }

                if (this.type === "tel") {
                    const maskPhone = servicePhoneMask(this.modelValue);

                    return maskPhone.valueInput; // maskPhone.modelValueInput valueInput
                }

                if (this.type === "date") {
                    try {
                        let date = new Date(this.modelValue);
                        const offset = date.getTimezoneOffset();
                        date = new Date(date.getTime() - offset * 60 * 1000);

                        return date.toISOString().split("T")[0];
                    } catch {
                        return this.modelValue;
                    }
                }

                if (this.type === "sdate") {
                    if (!this.modelValue) {
                        return "";
                    }

                    let [year, month, day] = this.modelValue.split("-");
                    if (day) {
                        day = day.slice(0, 2);
                    }

                    return [day, month, year].filter((item) => item).join(".");
                }

                if (
                    this.type === "range" &&
          this.max !== this.trueMax &&
          this.modelValue === this.max
                ) {
                    return this.trueMax;
                }

                return this.modelValue;
            },
            set(modelValue) {
                if (modelValue && this.maxlength) {
                    const result = modelValue.slice(0, this.maxlength);
                    this.$emit("update:modelValue", result);
                    this.$refs.input.value = result;
                    return;
                }

                if (this.type === "date") {
                    const result = modelValue ? modelValue + "T00:00:00+00:00" : "";
                    this.$emit("update:modelValue", result);
                    return;
                }

                if (this.type === "sdate") {
                    const maskResult = serviceDateMask(
                        this.$refs.input.value,
                        new Date().getFullYear()
                    );
                    const oldCaretPos = this.$refs.input.selectionStart;
                    const modelValueInput = setNumberMask(modelValue, "##.##.####");

                    this._setDataInInput({
                        modelValue,
                        oldCaretPos,
                        result: maskResult.result,
                        modelValueInput: modelValueInput,
                    });
                    return;
                }

                if (this.type === "tel") {
                    const maskPhone = servicePhoneMask(modelValue);
                    const oldCaretPos = this.$refs.input.selectionStart;

                    this._setDataInInput({
                        modelValue,
                        oldCaretPos,
                        result: maskPhone.result,
                        modelValueInput: maskPhone.valueInput,
                    });
                    return;
                }

                if (this.autocapitalize === "on") {
                    const maskResult = setCapitalizeMask(modelValue);
                    const oldCaretPos = this.$refs.input.selectionStart;

                    this._setDataInInput({
                        modelValue,
                        oldCaretPos,
                        result: maskResult,
                        modelValueInput: maskResult,
                    });
                    return;
                }

                if ((this.mask || "").indexOf("#") !== -1) {
                    const maskResult = setNumberMask(modelValue, this.mask);
                    const oldCaretPos = this.$refs.input.selectionStart;

                    this._setDataInInput({
                        modelValue,
                        oldCaretPos,
                        /*
            тут не надо делать всякие .replace(/ /g, "") 
            тк кроме редактирования есть и отображения
            */
                        result: maskResult,
                        modelValueInput: maskResult,
                    });
                    return;
                }

                if (
                    this.type === "range" &&
          Number(this.max) !== Number(this.trueMax) &&
          Number(modelValue) > Number(this.max)
                ) {
                    this.$emit("update:modelValue", this.max);
                    return;
                }

                this.$emit("update:modelValue", modelValue);
            },
        },
    },
    watch: {
        validationMessage: {
            handler: "onValidationMessageChange",
        },
    },
    mounted() {
        this.onValidationMessageChange(this.validationMessage);

        if (this.type === "date") {
            this.$refs.input.addEventListener("click", function () {
                try {
                    if (!this.isShow) {
                        this.showPicker();
                    }

                    this.isShow = !this.isShow;
                } catch (e) {
                    console.info(e);
                }
            });
        }
    },
    methods: {
        onChange(modelValue) {
            this.$emit("change", modelValue);

            if (this.type === "sdate") {
                const maskResult = serviceDateMask(
                    this.$refs.input.value,
                    new Date().getFullYear()
                );
                const oldCaretPos = this.$refs.input.selectionStart;

                this._setDataInInput({
                    modelValue,
                    oldCaretPos,
                    result: maskResult.result,
                    modelValueInput: maskResult.modelValueInput,
                });
                return; 
            }
        },
        _setDataInInput({ modelValue, oldCaretPos, result, modelValueInput }) {
            this.$emit("update:modelValue", result);

            this.$nextTick(() => {
                if (typeof modelValueInput === "undefined") {
                    return;
                }

                const newCaretPos = checPositionAfterMask(
                    modelValue,
                    modelValueInput,
                    oldCaretPos
                );

                this.$refs.input.value = modelValueInput;
                this.$refs.input.setSelectionRange(newCaretPos, newCaretPos);
                this._checkNextFocusByNameGroup(newCaretPos);
            });
        },
        _checkNextFocusByNameGroup(newCaretPos) {
            if ((this.mask || "").length === 0) {
                return;
            }

            const [fieldName, fieldIndex] = (this.name || "").split("-");

            if (this.mask.length <= newCaretPos && fieldName && fieldIndex) {
                const nextSibling = document.querySelector(
                    `input[name=${fieldName}-${parseInt(fieldIndex, 10) + 1}]`
                );

                if (nextSibling !== null) {
                    nextSibling.focus();
                }
            }
        },
        onValidationMessageChange(validationMessage) {
            if (this.$refs.input) {
                this.$refs.input.setCustomValidity(validationMessage || "");
                this.$refs.input.checkValidity();
            }
        },
    },
};
</script>

<style lang="scss" src="./input.scss">
</style>
