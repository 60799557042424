<template>
    <div v-if="!isFullScreen">
        <metainfo />
        <Header />
        <main :class="[!$route.name && 'loader__main']">
            <svg
                v-if="!$route.name"
                class="loader__spin"
                xmlns="http://www.w3.org/2000/svg"
                width="200"
                height="200"
            >
                <use xlink:href="#symbol__loader-spin" />
            </svg>
            <router-view />
        </main>
        <Footer v-if="$route.name !== '404'" />
    </div>
    <layoutFullScreen v-else>
        <router-view />
    </layoutFullScreen>
</template>

<script>
import Header from "@/components/common/header/Header";
import Footer from "@/components/common/footer/Footer";
import layoutFullScreen from "@/layouts/fullScreen/fullScreen"


export default {
    components: {
        Header,
        Footer,
        layoutFullScreen
    },
    computed:{
        isFullScreen(){
            return this.$route.meta.isFullScreen
        }
    },
    watch: {
        "$route.name"() {
            window.scrollTo(0, 0)
        }
    },
    mounted(){
        this.combackerPopstate(this.$route.path);
        const headID = document.getElementsByTagName("head")[0];
        const link = document.createElement("link");
        link.type = "text/css";
        link.rel = "stylesheet";
        headID.appendChild(link);

        link.href = "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,400&display=swap";

        setTimeout(()=>{
            (function (w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != "dataLayer" ? "&l=" + l : "";
                j.async = true;
                j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
                f.parentNode.insertBefore(j, f);
            })(window, document, "script", "dataLayer", "GTM-WVW22NC");
            // ебанный камбекер НЕ УДАЛЯТЬ!!!!
            this.historyPush();
        }, 1000);
    },
    methods: {
        combackerPopstate(path) {
            window.addEventListener("popstate", function (event) {
                if (event.state == -1 && path === "/") {
                    window.location.href = "/showcase";
                }
            }, false);
        },
        // ебанный камбекер НЕ УДАЛЯТЬ!!!!
        historyPush() {
            history.pushState(-1, null);
            history.pushState(0, null);
            history.pushState(1, null);
            history.go(-1);
        }
    }
}
</script>

<style lang="scss">
    @import "~@/styles/index.scss";
</style>
