<template>
    <header class="header__wrapper">
        <div class="header">
            <div class="header__block">
                <router-link
                    to="/"
                    class="header__logo"
                >
                    <svg
                        class="logo"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <title>Krediska</title>
                        <desc>Логотип</desc>
                        <use xlink:href="#symbol__logo" />
                    </svg>
                </router-link>
                <ul class="header__menu">
                    <li>
                        <a
                            :href="linkBorrow"
                        >
                            Занять
                        </a>
                    </li>
                    <li>
                        <router-link to="/qanda">
                            Вопросы-ответы
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/about-company">
                            Компания
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="header__block">
                <ul
                    v-if="!isLogin"
                    class="header__menu"
                >
                    <li>
                        <a :href="linkProfile">
                            Вход
                        </a>
                    </li>
                </ul>
                <ul
                    v-if="isLogin"
                    class="header__menu"
                >
                    <li>
                        <a
                            class="header__link"
                            :href="linkProfile + '/active'"
                        >Активный займ</a>
                    </li>
                    <li>
                        <a
                            class="header__link"
                            :href="linkProfile + '/history'"
                        >История</a>
                    </li>
                    <li>
                        <a
                            class="header__link"
                            :href="linkProfile + '/profile'"
                        >Профиль</a>
                    </li>
                    <li>
                        <button
                            class="header__menu-btn"
                            type="clear"
                            @click="clickLogout"
                        >
                            Выйти
                        </button>
                    </li>
                </ul>
            </div>
            <button
                class="header__humburger-btn"
                aria-label="menu"
                @click="showMenu = true"
            >
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M3 18H13V16H3V18ZM3 13H21V11H3V13ZM3 6V8H17V6H3Z"
                        fill="black"
                    />
                </svg>
            </button>
            <MobileMenu
                v-if="showMenu"
                :is-login="isLogin"
                @close="showMenu = false"
            />
        </div>
    </header>
</template>

<script>

import MobileMenu from "./MobileMenu"

export default {
    name: "KHeader",
    components: {
        MobileMenu,
    },
    data() {
        return {
            showMenu: false,
            isLogin: false,
        }
    },
    computed: {
        linkBorrow() {
            // eslint-disable-next-line no-undef
            return env.VUE_APP_BORROW
        },
        linkProfile() {
            // eslint-disable-next-line no-undef
            return env.VUE_APP_PROFILE
        }
    },
    created() {
        this.$infra.jsonrpc.validate().then(()=>{
            this.isLogin = true;
        }).catch(() => {
            this.isLogin = false;
        });
    },
    methods:{
        clickLogout(){
            this.$infra.jsonrpc.logout().then(() => {
                window.location = this.linkProfile;
            })
        }
    }
}
</script>

<style lang="scss">
@import "./header";

.header__menu-btn{
  display: inline-flex;
  align-items: center;
  width: auto;
  font-style: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  text-align: inherit;
  letter-spacing: inherit;
  color: inherit;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
}
</style>
