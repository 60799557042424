<template>
    <div
        class="mobile-menu"
        @click="$emit('close')"
    >
        <div class="mobile-menu__wrapper">
            <div class="mobile-menu__top">
                <a
                    class="mobile-menu__logo"
                    href="/"
                />
                <span
                    class="mobile-menu__close"
                    @click="$emit('close')"
                />
            </div>
            <ul class="mobile-menu__list">
                <li>
                    <a
                        :href="linkBorrow"
                    >
                        Занять
                    </a>
                </li>
                <li>
                    <router-link
                        :to="{
                            name: 'Q&A'
                        }"
                    >
                        Вопросы-ответы
                    </router-link>
                </li>
                <li>
                    <router-link
                        :to="{
                            name: 'AboutCompany'
                        }"
                    >
                        Компания
                    </router-link>
                </li>
                <li>
                    <router-link
                        :to="{
                            name: 'Documents'
                        }"
                    >
                        Документы
                    </router-link>
                </li>
                <li>
                    <router-link
                        :to="{
                            name: 'Contacts'
                        }"
                    >
                        Контакты
                    </router-link>
                </li>
            </ul>
            <ul
                v-if="!isLogin"
                class="mobile-menu__list"
            >
                <li>
                    <a :href="linkProfile">
                        Вход
                    </a>
                </li>
            </ul>
            <ul
                v-if="isLogin"
                class="mobile-menu__list"
            >
                <li>
                    <a
                        class="header__link"
                        :href="linkProfile + '/active'"
                    >Активный займ</a>
                </li>
                <li>
                    <a
                        class="header__link"
                        :href="linkProfile + '/history'"
                    >История</a>
                </li>
                <li>
                    <a
                        class="header__link"
                        :href="linkProfile + '/profile'"
                    >Профиль</a>
                </li>
                <li>
                    <button
                        class="header__menu-btn"
                        type="clear"
                        @click="clickLogout"
                    >
                        Выйти
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        isLogin: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        linkBorrow() {
            // eslint-disable-next-line no-undef
            return env.VUE_APP_BORROW
        },
        linkProfile() {
            // eslint-disable-next-line no-undef
            return env.VUE_APP_PROFILE
        }
    },
    created() {
        document.body.setAttribute("style", "overflow:hidden")
    },
    beforeDestroy() {
        document.body.removeAttribute("style")
    },
    methods:{
        clickLogout(){           
            this.$infra.jsonrpc.logout().then(() => {
                window.location = this.linkProfile;
            })
        }
    }
}
</script>

<style lang="scss" src="./mobile-menu.scss">
// @import "@colors";
// @import "./mobile-menu";

.mob-info-block {
    color: $almost-black;

    &__top {
        font-weight: 800;
        font-size: 32px;
        line-height: 1.45;
        margin: -10px 0 10px;
    }

    &__desc {
        margin: 0;
        padding: 0;
        color: #73869D;
        font-size: 14px;
        line-height: 1.4;
    }

    &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 1.8;
        margin: 0;
        padding: 0;
    }

    &__button {
        display: block;
        padding: 12px;
        border-radius: 40px;
        background: $green;
        margin: 12px 0 0;
        text-align: center;
        font-size: 16px;
        line-height: 1.8;
        font-weight: 600;
        text-decoration: none;
        color: #FFF;
    }
}

</style>