import { createWebHistory, createRouter } from "vue-router"

const router = new createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes: [
    
        {
            path: "/",
            name: "Home",
            component: () => import(/* webpackPrefetch: true */ "@/views/main/Main"),
        },
        {
            path: "/qanda",
            name: "Q&A",
            component: () => import(/* webpackChunkName: "qa-view",  webpackPrefetch: false */"@/views/pages/Q&A/Q&A"),
        },
        {
            path: "/view-document/:name",
            name: "view-document",
            component: () => import(/* webpackChunkName: "html-file",  webpackPrefetch: false */"@/components/html-file/html-file"),
        },
        {
            path: "/ask",
            name: "ask",
            component: () => import(/* webpackChunkName: "ask",  webpackPrefetch: false */"../views/pages/askForm/AskForm") 
        },
        {
            path: "/askResult/:result",
            name: "askResult",
            component: () => import(/* webpackChunkName: "askResult",  webpackPrefetch: false */"../views/pages/askForm/AskResult"),
            props: true
        },
        {
            path: "/about-company",
            component: () => import(/* webpackChunkName: "company-view",  webpackPrefetch: false */"@/layouts/company/Company"),
            children: [
                {
                    path: "/about-company",
                    name: "AboutCompany",
                    component: () => import(/* webpackChunkName: "about-view",  webpackPrefetch: false */"@/views/pages/company/About"),
                },
                {
                    path: "documents",
                    name: "Documents",
                    component: () => import(/* webpackChunkName: "document-view",  webpackPrefetch: false */"@/views/pages/company/Documents"),
                },
                {
                    path: "contacts",
                    name: "Contacts",
                    component: () => import(/* webpackChunkName: "contact-view",  webpackPrefetch: false */"@/views/pages/company/Contacts"),
                },
    
            ],
        },
        { 
            path: "/data-pay", 
            name: "data-pay",
            component: () => import(/* webpackChunkName: "data-pay",  webpackPrefetch: false */"@/views/data-pay") 
        },
        { 
            path: "/data-pay-qr", 
            name: "data-pay-qr",
            component: () => import(/* webpackChunkName: "data-pay-qr",  webpackPrefetch: false */"@/views/data-pay-qr") 
        },
        {
            name: "showcase",
            path: "/showcase",
            component: () =>
                import(
                /* webpackChunkName: "showcase" */ "@/views/combacker/combacker"
                ),
            meta: {
                isFullScreen: true,
            },
        },
        { 
            path: "/404", 
            name: "404",
            component: () => import(/* webpackChunkName: "contact-view",  webpackPrefetch: false */"@/views/pages/404/404") 
        }
    ],    
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    
})

router.beforeEach((to, from, next) => {
    if (to.matched.length) {
        next()
    } else {
        next("/")
    }
})


export default router
