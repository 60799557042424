<template>
    <footer class="footer">
        <div class="footer__wrapper footer__top">
            <section class="footer__block">
                <ul class="footer__menu">
                    <li>
                        <a
                            :href="link"
                        >
                            Занять
                        </a>
                    </li>
                    <li>
                        <router-link
                            :to="{
                                name: 'Q&A'
                            }"
                        >
                            Вопросы-ответы
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            :to="{
                                name: 'AboutCompany'
                            }"
                            @click.native="scrollToTop"
                        >
                            О компании
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            :to="{
                                name: 'Documents'
                            }"
                            @click.native="scrollToTop"
                        >
                            Документы
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            :to="{
                                name: 'Contacts'
                            }"
                            @click.native="scrollToTop"
                        >
                            Контакты
                        </router-link>
                    </li>
                </ul>
            </section>
            <section class="footer__block">
                <section class="footer__contacts">
                    <p class="footer__title">
                        Контактная<br> информация
                    </p>
                    <ul class="footer__contacts-list">
                        <li class="footer__phone">
                            <a
                                :href="contacts.phone.link"
                            >
                                {{ contacts.phone.text }}
                            </a>
                        </li>
                        <li>
                            <a
                                :href="contacts.email.link"
                            >
                                {{ contacts.email.text }}
                            </a>
                        </li>
                    </ul>
                </section>
            </section>
        </div>
        <LinksToApps />
        <div class="footer__wrapper">
            <section class="footer__block">
                <section class="footer__copyright">
                    <p
                        v-html="(isPartner ? footer.text_exclusion_partners : footer.text).join(' ')"
                    />
                </section>
            </section>
            <section class="footer__block">
                <ul class="footer__docs">
                    <li
                        v-for="(doc, i) in footer.docs"
                        :key="'footer__docs' + i"
                    >
                        <a
                            :href="doc.link"
                            target="_blank"
                        >
                            {{ doc.text }}
                        </a>
                    </li>
                </ul>
            </section>
        </div>
    </footer>
</template>

<script>
import LinksToApps from "./links-to-apps/links-to-apps.vue";

export default {
    components: {
        LinksToApps,
    },
    data() {
        return {
            footer: {
                text:[],
                text_exclusion_partners:[],
                docs:[]
            },
            contacts:{
                phone: {
                },
                email: {
                },
            }
        }
    },
    computed: {
        link() {
            // eslint-disable-next-line no-undef
            return env.VUE_APP_BORROW
        },
        isPartner() {
            return this.$route.query?.cpa == 1 && ["bankiruapp", "sravniruapp", "leadgidapp", "leadstechapp"].includes(this.$route.query?.utm_source);
        },
    },
    mounted(){
        import(/* webpackChunkName: "text-stub" */"@/texts/text.stub.js").then(res=>{
            this.footer = res.default.footer;
            this.contacts = res.default.contacts.props;
        })
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        }
    }
}
</script>

<style lang="scss">
@import "footer";
</style>
