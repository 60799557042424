<template>
    <div class="wrapper">
        <slot />
    </div>
</template>

<script>
export default {
};
</script>
