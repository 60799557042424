// @ts-check

/**
 * Маска для даты
 * @param {string} value - текст из input
 * @param {Number} fullYear - (new Date()).getFullYear() для определения минимальной даты
 */
function serviceDateMask(value, fullYear) {
    let valueInput = setNumberMask(value, "##.##.####");
    let result = "";
    const minYear = fullYear - 100;
    const maxYear = fullYear;

    if (valueInput.length < 10) {
        valueInput = "";
    }

    if (valueInput.length >= 10) {
        let [day = "", month = "", year = ""] = valueInput
            .split(".")
            .map((item, index) => {
                const isNullIndex = index === 0;
                const isFirstIndex = index === 1;
                const isTwoIndex = index === 2;

                if ((isNullIndex || isFirstIndex) && Number(item) === 0) {
                    item = "01";
                }
                if (isNullIndex && Number(item) > 31) {
                    item = "31";
                }

                if (isFirstIndex && Number(item) > 12) {
                    item = "12";
                }

                if (isTwoIndex && Number(item) < minYear) {
                    item = String(minYear);
                }
                if (isTwoIndex && Number(item) > maxYear) {
                    item = String(maxYear);
                }

                return item;
            });

        const checkDate = new Date(
            [year, month, day ? day + "T00:00:00+00:00" : day].join("-")
        );

        if (checkDate.getDate() !== Number(day)) {
            const lastDateInMonth = new Date(Number(year), Number(month), 0);
            day = String(lastDateInMonth.getDate());
        }

        result = [year, month, day ? day + "T00:00:00+00:00" : day].join("-");
        valueInput = [day, month, year].join(".");
    }

    return {
        valueInput,
        result,
    };
}

/**
 * Маска для телефона
 *
 * @param {string} value - текст из input
 * @typedef {Object} ResultPhoneMask
 * @property {string} valueInput - значение инпута
 * @property {string} result - результирующие значения
 * @return {ResultPhoneMask}
 */
function servicePhoneMask(value) {
    let mask = "+7 (###) ### ## ##";

    const v = String(value).replace(/[^\d^+^(^)^ ]/g, "");
    const isPlus = v[0] === "+";
    const is8 = (isPlus && v[1] === "8") || v[0] === "8";

    if (is8) {
        mask = "# (###) ### ## ##";
    }

    const valueInput = setNumberMask(v, mask);
    const result =
    valueInput && "+7" + valueInput.replace(/[^\d]/g, "").substr(1);

    return {
        valueInput,
        result,
    };
}

/**
 * Правильная установка позиции курсора после применения маски
 *
 * @param {string} oldVal - старое значение
 * @param {string} newVal - новое значение
 * @param {number} pos - старая позиция курсора
 * @return {number} - новая позиция курсора
 */
function checPositionAfterMask(oldVal, newVal, pos) {
    if (pos === undefined) {
        return newVal.length;
    }

    if (oldVal === newVal) {
        return pos;
    }

    const previousCharPos = pos - 1;
    let offset = 0;
    let i = pos;

    while (
        oldVal[previousCharPos] !== newVal[previousCharPos + offset] &&
    i < newVal.length
    ) {
        offset++;
        i++;
    }

    return pos + offset;
}

function setNumberMask(val, mask) {
    const newVal = [];
    const maskChars = mask.split("");
    const valChars = val.split("");
    const maskLength = maskChars.length;
    let i = 0;
    let offset = 0;

    while (i < maskLength) {
        const maskChar = maskChars[i];
        const valChar = valChars[i + offset];

        const isNotMask = maskChar !== "#";

        if (valChar === undefined) {
            break;
        }

        if (isNotMask && maskChar !== valChar) {
            offset--;
        }

        if (isNotMask || (valChar !== " " && !isNaN(+valChar))) {
            newVal.push(isNotMask ? maskChar : valChar);
            i++;

            continue;
        }

        offset++;
    }

    return newVal.join("");
}

function setCapitalizeMask(val) {
    const value = String(val || "").trim();
    return value.length
        ? value[0].toUpperCase() + value.slice(1).toLowerCase()
        : value;
}

export {
    servicePhoneMask,
    checPositionAfterMask,
    setNumberMask,
    setCapitalizeMask,
    serviceDateMask,
};
