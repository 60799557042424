<template>
    <div class="links-to-apps">
        <a 
            v-for="(link,i) in links"
            :key="'links-to-apps' + i"
            :href="link.href" 
            target="_blank" 
            rel="noopener noreferrer" 
            :title="link.title" 
            :class="['links-to-apps__link' , 'links-to-apps__link_' + link.type]"
        />
    </div>
</template>

<script>

export default {
    name: "AmountAndTermRange",
    data() {
        return {
            links: [
                {
                    href: "https://play.google.com/store/apps/details?id=ru.webview.krediska",
                    title: "Приложение GooglePlay!",
                    type: "google"
                },
                {
                    href: "https://appgallery.huawei.ru/app/C106583431",
                    title: "Приложение AppGallery!",
                    type: "huawei"
                },
                {
                    href: "https://apps.rustore.ru/app/ru.webview.krediska",
                    title: "Приложение RuStore!",
                    type: "rustore"
                },
                // {
                //     href: 'https://apps.rustore.ru/app/ru.webview.krediska',
                //     title: 'Приложение NASHSTORE!',
                //     type: 'nashstore'
                // },
                // {
                //     href: 'https://apps.rustore.ru/app/ru.webview.krediska',
                //     title: 'Приложение App Store!',
                //     type: 'appstore'
                // }
            ],
        };
    },
}
</script>

<style lang="scss">
    .links-to-apps {
        display: flex;
        flex-wrap: wrap;
        max-width: 960px;
        margin: 0 auto;
        margin-bottom: 32px;
    }

    .links-to-apps__link {
      display: block;
      width: 140px;
      min-width: 140px;
      height: 40px;
      background-position: 50%;
      background-size: contain;
      background-repeat: no-repeat;
      margin: .5rem .5rem;
    }

    .links-to-apps__link_appstore {
      background-image: url("./assets/appstore.svg");
    }

    .links-to-apps__link_google {
      background-image: url("./assets/google.svg");
    }

    .links-to-apps__link_huawei {
      background-image: url("./assets/huawei.svg");
    }

    .links-to-apps__link_nashstore {
      background-image: url("./assets/nashstore.svg");
    }

    .links-to-apps__link_rustore {
      background-image: url("./assets/rustore.svg");
    }
</style>
